// @flow

import { map } from 'ramda'

import { generateIdFromTitle } from '../../../helpers'
import type { OverviewItem } from '../../../types'

const overview = [
  {
    title: 'Beautiful Dolphin Cottage has 2 decks!',
    message: 'One on the west side and one on the east side.',
  },
  {
    title: 'Beautiful Views',
    message:
      'The east deck affords lovely views up and down beautiful Estero Blvd, and the bay across the street.',
  },
  {
    title: 'Room Configuration',
    message: '2 Bedroom (1 Q, 1 Q) 1 Bath.',
  },
]

export const dolphinOverview: OverviewItem[] = map(
  generateIdFromTitle,
  overview,
)

export default dolphinOverview
